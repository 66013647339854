.embed-container,table {
    width: 100%;
}
.embed-container,
.table-1__body,
table {
    border-left: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
}

table,
td,
tr,
th {
    padding: 0;
    border-spacing: 0;
    border: none;
    padding: 10px !important;
}

.embed-container {
    max-width: 1200px;
    margin: 0 auto;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

img {
    width: 100%
}
.mobile-spread {
  
}
@media screen and (max-width: 600px) {
    .column {
        width: 100%;
    }
}