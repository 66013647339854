.bottom-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    max-width: 1180px;
    margin: 0 auto;
    align-items: center;
    border-top: 1px;
    border-top-color: #e3e3e4;
    border-top-style: solid;
    border-bottom: 1px;
    border-bottom-color: #040404;
    border-bottom-style: solid;
    margin-bottom: 20px;
    overflow-wrap: break-word;
}

@media (max-width: 767px) {
    .bottom-nav-container {
        display: none;
    }
}

@media (min-width: 768px) {
    .bottom-nav-container {
        display: block;
    }
}

.bottom-nav-nav-links {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

}

.bottom-nav-nav-links li {
    position: relative;
    white-space: nowrap;
}

.bottom-nav-nav-links li.highlight {
    color: #fff;
    background-color: #000;
    margin-left: auto;
}

.bottom-nav-nav-links li:hover {
    box-shadow: inset 0 -2px 0 0 #4646e8;
    font-weight: 700;
}

.bottom-nav-nav-links a {
    cursor: pointer;
    font-family: Inter, sans-serif, arial;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.17;
    border: none;
    outline: none;
    padding: 16px 12px;
    background-color: inherit;
    display: block;
    text-decoration: none;
    color: unset;

}

.bottom-nav-nav-links :hover>.dropdown {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    background-color: #fcfcfc;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    z-index: 200;
    margin-left: 0;
    margin-top: 0;
    padding: 12px 16px;
    min-width: 181px;
    border-top: 1px solid;
}

.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    background-color: #333;
}

.dropdown ul {
    width: 100%;
}

.dropdown li {
    padding: 2px 16px;
    list-style: none;
}


.dropdown li:hover {
    background-color: #f1f1f2;
    font-weight: 700;
    color: #040404;
    text-decoration: none;
    text-align: left;
    max-height: 100%;
    width: 100%;
    display: block;
    box-shadow: none;
    padding: 2px 16px;

}

.dropdown a {
    display: block;
    text-decoration: none;
    font-size: 12px;
}

@media (max-width: 767px) {
    .mobile-menu {
        display: block;
    }
}

@media (min-width: 768px) {
    .mobile-menu {
        display: none;
    }
}